<template>
    <v-container>
        <div v-if="mode && !['edit', 'create'].includes(mode)" class="edit-button-wrapper">
            <v-btn class="mx-2" dark medium color="#004cff" @click="onEdit">
                <v-icon dark>mdi-account-edit</v-icon>
            </v-btn>
        </div>
        <v-form :readonly="readOnly">
            <v-text-field
                v-model="dealer.firstName"
                label="First Name"
                :clearable="clearable"
            />
            <v-text-field
                v-model="dealer.lastName"
                label="Last Name"
                :clearable="clearable"
            />
            <v-text-field
                v-model="dealer.email"
                label="Email"
                :clearable="clearable"
            />
            <v-text-field
                v-model="dealer.password"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show1 ? 'text' : 'password'"
                label="Password"
                @click:append="show1 = !show1"
                :clearable="clearable"
            />
            <v-menu
                :close-on-content-click="true"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="dealer.birthDate"
                        label="Choose your birth date"
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        :readonly="readOnly"
                        clearable
                        v-on="on"
                    />
                </template>
                <v-date-picker
                    v-model="dealer.birthDate"
                    :model-config="modelConfig"
                    class="datepicker"
                    :readonly="readOnly"
                />
            </v-menu>
            <div v-if="mode !== 'show'" class="submit-button-wrapper">
                <v-btn class="my-2 white--text" :disabled="shouldDisableSubmit()" large color="#004cff" @click="onSubmit">
                    Submit
                </v-btn>
            </div>
        </v-form></v-container>
</template>

<script>
    import dealerService from '@/services/dealer-service'

    export default {
        name: 'DealerForm',
        data: () => {
            return {
                show1: false,
                mode: null,
                readOnly: false,
                clearable: true,
                dealerId: null,
                modelConfig: {
                    type: 'String',
                    mask: 'yyyy-MM-DD'
                },
                imgUrl: null,
                dealer: {
                    firstName: '',
                    lastName: '',
                    birthDate: null,
                    email: '',
                    password: ''
                },
                rules: [
                    v => !!v || 'Field is required'
                ]
            }
        },
        created() {
            this.mode = this.$route.params.mode
            if (this.mode === 'create') {
                return
            }
            if (this.mode === 'show') {
                this.readOnly = true
                this.clearable = false
                this.editorInit.setup = function(editor) {
                    editor.setMode('readonly')
                }
            }
            this.dealerId = this.$route.params.dealerId
            if (this.dealerId) {
                dealerService.getDealerById(this.dealerId).then(resp => {
                    this.dealer = resp.data.dealer
                })
            }
        },
        methods: {
            async onSubmit() {
                if (this.mode === 'edit') {
                    this.editDealer()
                } else if (this.mode === 'create') {
                    this.createDealer()
                }
            },
            async createDealer() {
                const response = await dealerService.createDealer(this.dealer)
                if (response.data.id) {
                    this.dealerId = response.data.id
                }
                this.$router.push(`/dealers`)
            },
            async editDealer() {
                await dealerService.editDealerById(this.dealerId, this.dealer)
                this.$router.push(`/dealers`)
            },
            shouldDisableSubmit() {
                return !this.dealer.firstName && !this.dealer.lastName
            },
            onEdit() {
                this.$router.push(`/dealers/${this.dealerId}/edit`)
            }
        }
    }
</script>

<style scoped>
.edit-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.submit-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 2vh;
}

</style>
